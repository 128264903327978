import { NextPage } from "next";
import { getGlobals } from "@models/globals.model";
import { getModulePage, ModulePage } from "@models/module-page.model";
import { Sanity } from "@models/sanity.model";
import ComponentResolver from "@components/component-resolver";
import Layout from "@components/layout";
import { getSectioningContext } from "@components/component-resolver/sectionContext";

interface HomeProps extends Sanity.PageProps {
  page: ModulePage;
}

const Home: NextPage<HomeProps> = ({ globals, page }) => {
  const { marketing_modules, hide_header_banner, seo, transparent_header } = page;

  return (
    <Layout
      globals={globals}
      pageID={page?.slug?.current}
      hideBanner={hide_header_banner}
      transparentHeader={transparent_header}
      seo={seo}>
      {marketing_modules?.length > 0 &&
        marketing_modules.map((schema: Sanity.Document, i: number) => {
          const sectioningContext = getSectioningContext({ modules: marketing_modules, index: i });

          return (
            <ComponentResolver
              key={`${schema._type}_${i}`}
              schema={schema}
              sectioningContext={sectioningContext}
            />
          );
        })}
    </Layout>
  );
};

export default Home;

export async function getStaticProps() {
  const [globals, page] = await Promise.all([await getGlobals(), await getModulePage("home")]);

  if (!page) {
    return {
      notFound: true,
    };
  }

  return {
    props: {
      globals,
      page,
    },
  };
}
